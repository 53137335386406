import React, { useCallback, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { BannerSlideProps } from "@/app/interface/components";
import ImageComponent from "./ImageComponent";
import CustomLink from "./CustomLink";
import classNames from "classnames";
import { useBannerContext } from "@/app/contexts/BannerContext";
import { BRAND_NAMES } from "@/app/utils/utils";

const BannerSlide: React.FC<BannerSlideProps> = ({
                                                   btnsDisabled,
                                                   setIsConditionsModalOpen,
                                                   setConditionsContent,
                                                   banners,
                                                   links,
                                                   setChatbotOpen,
                                                   width,
                                                   height,
                                                   calls,
                                                   isCMS,
                                                   isMobileCMS,
                                                   priority = true,
                                                 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFirstImageLoaded, setIsFirstImageLoaded] = useState(false);
  const isLoopEnabled = banners.length > 1;
  const { contents, defenseTypes } = useBannerContext();
  
  const handleSlideChange = useCallback(
        (swiper: { realIndex: React.SetStateAction<number> }) => {
          setActiveIndex(swiper.realIndex);
        },
        []
  );
  
  const extractDomain = useCallback((url: string): string | null => {
    if (!url) return "";
    try {
      const urlObj = new URL(url);
      return urlObj.hostname;
    } catch (error) {
      console.error("URL inválida:", error);
      return null;
    }
  }, []);
  
  const extractRelativePath = useCallback((url: string): string => {
    if (!url) return "";
    try {
      const urlObject = new URL(url);
      return urlObject.pathname + urlObject.search;
    } catch (error) {
      console.error("Invalid URL provided:", error);
      return "";
    }
  }, []);
  
  const isSolicitarProposta = useCallback((path: string) => {
    const regex = /^\/solicitar-proposta(\/.+|\?.+)/;
    return regex.test(path);
  }, []);
  
  const getRouteDisplayName = useCallback((path: string) => {
    const routeMap: { [key: string]: string } = {
      "/": "Home",
      "/venda-direta/pessoa-com-deficiencia": "Pessoa com Deficiência",
      "/venda-direta/produtor-rural": "Produtor Rural",
      "/venda-direta/taxista": "Taxista",
      "/venda-direta/frotista": "Frotista",
      "/servicos/consorcio": "Consórcio",
      "/servicos/pos-venda": "Pós venda",
      "/touchpoint": "Touch Point 1",
      "/touch-point": "Touch Point 2",
      "/corolla-cross": "Corolla Cross",
      "/corolla-hatch": "Corolla Hatch",
      "/corolla": "Corolla",
      "/hilux": "Hilux",
      "/rav4": "RAV4",
      "/sw4": "SW4",
      "/yaris": "Yaris",
    };
    
    return `Ir para ${routeMap[path] || path}`;
  }, []);
  
  return (
        <div
              className={classNames("bannerSlideContainer", {
                "cursor-grabbing": banners.length > 1,
              })}
        >
          <Swiper
                spaceBetween={0}
                centeredSlides={true}
                loop={isLoopEnabled}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                  stopOnLastSlide: false,
                }}
                pagination={{
                  clickable: true,
                  el: ".custom-pagination",
                  renderCustom: (swiper, current, total) => {
                    return Array.from({ length: total })
                          .map((_, index) => {
                            return `<span class="swiper-pagination-bullet${
                                  index + 1 === current
                                        ? " swiper-pagination-bullet-active"
                                        : ""
                            }"></span>`;
                          })
                          .join("");
                  },
                }}
                navigation={false}
                grabCursor={true}
                touchEventsTarget="wrapper"
                onSlideChange={handleSlideChange}
                modules={[Autoplay, Pagination, Navigation]}
                className="bannerSlide"
                style={{ aspectRatio: width / height }}
          >
            {banners.map((banner, index) =>
                  banner ? (
                        <SwiperSlide key={index}>
                          <ImageComponent
                                src={banner}
                                alt=""
                                width={width}
                                height={height}
                                priority={index === 0 && priority}
                                loading={index === 0 ? "eager" : "lazy"}
                                onLoadingComplete={() => {
                                  if (index === 0) setIsFirstImageLoaded(true);
                                }}
                          />
                        </SwiperSlide>
                  ) : null
            )}
          </Swiper>
          {!btnsDisabled && (
                <div className="custom-buttons">
                  {links?.[activeIndex] ? (
                        <CustomLink
                              aria-hidden
                              tabIndex={-1}
                              className="btn-tertiary"
                              href={links[activeIndex]}
                              target={
                                extractDomain(links[activeIndex]) ===
                                `www.${BRAND_NAMES.URL}.com.br`
                                      ? undefined
                                      : "_blank"
                              }
                        >
                          Ir Para o Link
                        </CustomLink>
                  ) : Array.isArray(calls?.[activeIndex]) ? (
                        calls[activeIndex]
                              .filter((call) => call !== "/")
                              .map((call, idx) => (
                                    <CustomLink
                                          key={`${call}-${idx}`}
                                          type="button"
                                          className="btn-tertiary"
                                          href={call}
                                    >
                                      {getRouteDisplayName(call)}
                                    </CustomLink>
                              ))
                  ) : calls?.[activeIndex] && calls[activeIndex] !== "/" ? (
                        <CustomLink
                              type="button"
                              className="btn-tertiary"
                              href={calls[activeIndex]}
                        >
                          {getRouteDisplayName(calls[activeIndex])}
                        </CustomLink>
                  ) : null}
                </div>
          )}
          <div className="custom-pagination"></div>
        </div>
  );
};

export default React.memo(BannerSlide);
